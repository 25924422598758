<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>{{ $t("navbar.user_admin") }}</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        v-if="selected"
        persistent
        scrollable
        v-model="showEdit"
        max-width="600px"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.username"
                      v-bind:label="$t('salesforce.UserName')"
                      required
                      :rules="usernameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('salesforce.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.surname"
                      v-bind:label="$t('salesforce.SurName')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.residence"
                      v-bind:label="$t('salesforce.ResidenceCity')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.email"
                      v-bind:label="$t('salesforce.email')"
                      required
                      :rules="selected.status === 'inactive' ? [] : emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.phone"
                      v-bind:label="$t('salesforce.phone')"
                      required
                      :rules="lengthRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.phone_company"
                      v-bind:label="$t('salesforce.phone_company')"
                      required
                      :rules="lengthRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.dni"
                      v-bind:label="$t('salesforce.dni')"
                      required
                      :rules="[...requiredRules, ...lengthRules]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.brands"
                      v-bind:label="$t('project')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_projects"
                      :loading="isProjectsSearching"
                      :items="filtered_projects"
                      hide-no-data
                      filled
                      multiple
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.discount_km"
                      v-bind:label="$t('discount_km')"
                      :type="'number'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.role"
                      v-bind:label="$t('salesforce.user_type')"
                      :items="user_type"
                      filled
                      required
                      :rules="requiredRules"
                      @change="update_user_type"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.department"
                      :label="$t('salesforce.department')"
                      :items="departments"
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="selected.role === 'brand'">
                  <v-col cols="12" sm="12" md="6"
                    ><v-autocomplete
                      v-model="selected.isEdit"
                      :label="`es editar`"
                      :items="['Editar', 'No editar']"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="12" sm="12" md="6"
                    ><v-autocomplete
                      v-model="selected.landingPage"
                      :label="`Página de destino`"
                      :items="landingPages"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.parent_id"
                      v-bind:label="$t('salesforce.Parent')"
                      :items="user_list"
                      item-text="username"
                      item-value="id"
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.routes"
                      :label="$t('route')"
                      :items="routes"
                      item-text="name"
                      item-value="id"
                      filled
                      clearable
                      :required="isRequiredRoute"
                      :rules="isRequiredRoute ? requiredRules : []"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.companyCode"
                      :label="$t('Company')"
                      :items="companies"
                      item-text="name"
                      item-value="id"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.dateEndOfVacation"
                      v-bind:label="`Fecha Fin Vacaciones`"
                      clearable
                      required
                      :rules="requiredRules"
                      @change="onChangeDateEndOfVacation"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-btn
                          color="primary"
                          class="ml-2 white--text"
                          @click="addNewDateEntry"
                        >
                          <v-icon dark>mdi-plus</v-icon> añadir
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <table
                          class="table b-table table-striped table-bordered table-sm b-table-stacked-sm routepage_ps_table mb-0"
                        >
                          <colgroup>
                            <col width="40%" />
                            <col width="40%" />
                            <col width="20%" />
                          </colgroup>
                          <tr>
                            <th class="text-center">
                              {{ $t("brands.start_date") }}
                            </th>
                            <th class="text-center">
                              {{ $t("brands.end_date") }}
                            </th>
                            <th class="text-center"></th>
                          </tr>
                          <template
                            v-if="selected.user_date_entries.length > 0"
                          >
                            <tr
                              v-for="(date_item,
                              date_item_key) in selected.user_date_entries"
                              :key="`date_table_row_${date_item_key}`"
                            >
                              <td class="text-center">
                                <v-menu
                                  ref="start_date_menu"
                                  v-model="select_start_date_menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                  v-if="
                                    isLastUserDateEntry(
                                      selected.user_date_entries,
                                      date_item_key
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="
                                        selected.user_date_entries[
                                          date_item_key
                                        ].userStartDate
                                      "
                                      v-bind:label="$t('brands.start_date')"
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      v-on="on"
                                      readonly
                                      required
                                      :rules="
                                        selected.isSelectableDates
                                          ? requiredRules
                                          : selectableRules
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="
                                      selected.user_date_entries[date_item_key]
                                        .userStartDate
                                    "
                                    no-title
                                    @input="select_start_date_menu = false"
                                    :first-day-of-week="1"
                                    @change="onChangeDates(date_item_key)"
                                  ></v-date-picker>
                                </v-menu>
                                <span v-else>
                                  {{ date_item?.userStartDate }}
                                </span>
                              </td>
                              <td class="text-center">
                                <v-menu
                                  ref="end_date_menu"
                                  v-model="select_end_date_menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                  v-if="
                                    isLastUserDateEntry(
                                      selected.user_date_entries,
                                      date_item_key
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="
                                        selected.user_date_entries[
                                          date_item_key
                                        ].userEndDate
                                      "
                                      v-bind:label="$t('brands.end_date')"
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      v-on="on"
                                      readonly
                                      required
                                      :rules="
                                        selected.isSelectableDates
                                          ? requiredRules
                                          : selectableRules
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="
                                      selected.user_date_entries[date_item_key]
                                        .userEndDate
                                    "
                                    no-title
                                    scrollable
                                    @input="select_end_date_menu = false"
                                    :first-day-of-week="1"
                                    @change="onChangeDates(date_item_key)"
                                  ></v-date-picker>
                                </v-menu>
                                <span v-else>
                                  {{ date_item?.userEndDate }}
                                </span>
                              </td>
                              <td class="text-center">
                                <v-icon
                                  color="red"
                                  v-if="
                                    isLastUserDateEntry(
                                      selected.user_date_entries,
                                      date_item_key
                                    ) && selected.user_date_entries.length > 1
                                  "
                                  @click="removeLastDateEntry()"
                                >
                                  mdi-delete
                                </v-icon>
                              </td>
                            </tr>
                          </template>
                        </table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.km_in_liquidation"
                      v-bind:label="$t('km_in_liquidations')"
                      :items="km_in_liquidations"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.liquidationType"
                      v-bind:label="$t('Liquidation Type')"
                      :items="liquidationTypes"
                      item-text="label"
                      item-value="value"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="d-flex justify-center"
                  >
                    <v-radio-group
                      v-model="selected.status"
                      row
                      required
                      :rules="requiredRules"
                    >
                      <v-radio label="Active" value="active"></v-radio>
                      <v-radio label="Inactive" value="inactive"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="selected.id !== undefined">
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.additional_static_pendingholiday_year"
                      v-bind:label="$t('Year')"
                      :type="'number'"
                      clearable
                      :rules="yearRules"
                      @input="onChangeAdditionalPendingHolidayYear"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.additional_static_pendingholiday_value"
                      v-bind:label="$t('holidaypending')"
                      :type="'number'"
                      clearable
                      :rules="additionalHolidayRules"
                      @input="onChangeAdditionalPendingHolidayValue"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="isSaving"
                @click="onSaveClick(selected)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{
            $t("salesforce.user_confirmdelete")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              v-if="!isManager"
              color="primary"
              dark
              class="mb-2"
              @click="onItemEdit"
            >
              {{ $t("newitem") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="downloadExcelTemplateAPI('USERS')"
              class="mb-2 ml-2"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              v-if="!isManager"
              color="success"
              dark
              class="mb-2 ml-2"
              :loading="isSelecting"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonText }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn
              color="warning"
              dark
              class="mb-2 ml-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="users_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="users"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalusers"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";

export default {
  name: "Users",
  data: function() {
    return {
      totalusers: 0,
      users: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: [
        "username",
        "name",
        "surname",
        "residence",
        "domainName",
        "role",
        "department",
        "parent_user_name",
        "route_value",
        "status",
        "km_in_liquidation",
        "email",
        "phone",
        "phone_company",
        "dni",
        "dateEndOfVacation",
        "start_date",
        "end_date",
        "project",
        "companyCode",
        "discount_km",
        "liquidationType"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (rowIndex % 2 !== 0) {
            return "table-body-cell-even";
          } else {
            return "table-body-cell-odd";
          }
        }
      },
      filterCriteria: {
        role: {},
        department: {},
        status: {},
        km_in_liquidation: {},
        dateEndOfVacation: {},
        start_date: {},
        end_date: {},
        discount_km: {},
        companyCode: {},
        liquidationType: {}
      },
      showEmpty: false,

      loading: true,
      options: {},
      editedIndex: -1,
      selected: null,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      selectableRules: [
        v => !!v || "Required",
        v => "Las fechas de varias inscripciones no deben superponerse."
      ],
      usernameRules: [
        v => !!v || "Required",
        v => !v || v.length < 10 || "¡Desborde la longitud!"
      ],
      lengthRules: [v => !v || v.length < 10 || "¡Desborde la longitud!"],
      emailRules: [
        v => !!v || "Required",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      yearRules: [v => !v || v > 1970 || "Incorrect Year !"],
      additionalHolidayRules: [
        v => !v || (v >= -22 && v < 23) || "Incorrect Pending Holiday !"
      ],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      showEdit: false,
      dialogDelete: false,
      deleteItemId: -1,
      user_type: [],
      departments: [],
      companies: [],
      landingPages: [],
      user_status: ["active", "inactive"],
      km_in_liquidations: ["Yes", "No"],
      routes: [],
      liquidationTypes: [],

      parent_items: [],
      isParentLoading: false,
      search: null,
      user_list: [],
      select_start_date_menu: false,
      select_end_date_menu: false,

      search_projects: null,
      isProjectsSearching: false,
      filtered_projects: [],
      isSaving: false,

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /************************************* Loading Spinner Overlay ************************************/
      isLoadingSpinner: false
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async search(val) {
      await this.searchParent(val);
    },
    async search_projects(val) {
      this.search_brands(val);
    }
  },
  computed: {
    ...mapGetters("auth", ["isManager"]),
    isRequiredRoute() {
      let ret = false;
      if (this.selected && this.selected?.role && this.selected.role == "gpv") {
        ret = true;
      }
      return ret;
    },
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    col_edit() {
      return {
        key: "q",
        title: this.$t("salesforce.Edit"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
            </div>
          );
        }
      };
    },
    col_resetpassword() {
      return {
        key: "password",
        title: this.$t("salesforce.Password"),
        field: "password",
        align: "left",
        width: 100,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="primary"
                dark
                small
                on-click={() => this.onResetPassword(row.id)}
              >
                {this.$t("Reset")}
              </v-btn>
            </div>
          );
        }
      };
    },
    columns() {
      let common_cols = [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 70,
          sortBy: "ASC",
          fixed: "left"
        },
        {
          key: "username",
          title: this.$t("salesforce.UserName"),
          field: "username",
          align: "left",
          width: 200,
          sortBy: "",
          fixed: "left",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["username"]}
                    on-input={value =>
                      (this.filterCriteria["username"] = value)
                    }
                    placeholder="Search username"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "username")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "name",
          title: this.$t("salesforce.name"),
          field: "name",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["name"]}
                    on-input={value => (this.filterCriteria["name"] = value)}
                    placeholder="Search name"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "surname",
          title: this.$t("salesforce.SurName"),
          field: "surname",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["surname"]}
                    on-input={value => (this.filterCriteria["surname"] = value)}
                    placeholder="Search surname"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "surname")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "email",
          title: this.$t("salesforce.email"),
          field: "email",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["email"]}
                    on-input={value => (this.filterCriteria["email"] = value)}
                    placeholder="Search email"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "email")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "domainId",
          title: "Dominio",
          field: "domainId",
          align: "left",
          width: 100,
          sortBy: "DESC"
        },
        {
          key: "domainName",
          title: "Nombre de dominio",
          field: "domainName",
          align: "left",
          width: 200,
          sortBy: "ASC",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["domainName"]}
                    on-input={value =>
                      (this.filterCriteria["domainName"] = value)
                    }
                    placeholder="Search Domain Name"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "domainName")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "phone",
          title: this.$t("salesforce.phone"),
          field: "phone",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["phone"]}
                    on-input={value => (this.filterCriteria["phone"] = value)}
                    placeholder="Search phone"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "phone")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "phone_company",
          title: this.$t("salesforce.phone_company"),
          field: "phone_company",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["phone_company"]}
                    on-input={value =>
                      (this.filterCriteria["phone_company"] = value)
                    }
                    placeholder="Search phone company"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "phone_company")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "dni",
          title: this.$t("salesforce.dni"),
          field: "dni",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["dni"]}
                    on-input={value => (this.filterCriteria["dni"] = value)}
                    placeholder="Search DNI"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "dni")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "residence",
          title: this.$t("salesforce.ResidenceCity"),
          field: "residence",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["residence"]}
                    on-input={value =>
                      (this.filterCriteria["residence"] = value)
                    }
                    placeholder="Search residence"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "residence")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "companyCode",
          title: this.$t("Company"),
          field: "companyCode",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.company && row.company.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  {this.companies.map(item => {
                    return (
                      <v-checkbox
                        v-model={this.filterCriteria["companyCode"][item.id]}
                        label={item.name}
                        value={item.id}
                      ></v-checkbox>
                    );
                  })}
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "companyCode")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "role",
          title: this.$t("salesforce.user_type"),
          field: "role",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  {this.user_type.map(item => {
                    return (
                      <v-checkbox
                        v-model={this.filterCriteria["role"][item]}
                        label={item}
                        value={item}
                      ></v-checkbox>
                    );
                  })}
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "role")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "parent_user_name",
          title: this.$t("salesforce.Parent"),
          field: "parent_user_name",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.Parent && row.Parent.username}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["parent_user_name"]}
                    on-input={value =>
                      (this.filterCriteria["parent_user_name"] = value)
                    }
                    placeholder="Search parent"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "parent_user_name")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "department",
          title: this.$t("salesforce.department"),
          field: "department",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  {this.departments.map(item => {
                    return (
                      <v-checkbox
                        v-model={this.filterCriteria["department"][item]}
                        label={item}
                        value={item}
                      ></v-checkbox>
                    );
                  })}
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "department")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "project",
          title: this.$t("project"),
          field: "project",
          align: "left",
          width: 200,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            let brand_names = row.brands.map(item => item.name);
            return <span>{brand_names.join(", ")}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["project"]}
                    on-input={value => (this.filterCriteria["project"] = value)}
                    placeholder="Search project"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "project")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "route_value",
          title: this.$t("salesforce.Route(s)"),
          field: "route_value",
          align: "left",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["route_value"]}
                    on-input={value =>
                      (this.filterCriteria["route_value"] = value)
                    }
                    placeholder="Search ..."
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "route_value")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         {this.routes.map((item) => {
          //           return (
          //             <v-checkbox
          //               v-model={this.filterCriteria["route_value"]}
          //               label={item.name}
          //               value={item.id}
          //             ></v-checkbox>
          //           );
          //         })}
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "route_value")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   },
          // },
        },
        {
          key: "liquidationType",
          title: this.$t("Liquidation Type"),
          field: "liquidationType",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.liquidationTypeLabel}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["liquidationType"]}
                    dataList={this.liquidationTypes}
                    filterCriteria={this.filterCriteria["liquidationType"]}
                    itemvalue="value"
                    itemlabel="label"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "liquidationType")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "discount_km",
          title: this.$t("discount_km"),
          field: "discount_km",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["discount_km"]["from"]}
                    value={this.filterCriteria["discount_km"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["discount_km"]["to"]}
                    value={this.filterCriteria["discount_km"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "discount_km")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "km_in_liquidation",
          title: this.$t("km_in_liquidations"),
          field: "km_in_liquidation",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  {this.km_in_liquidations.map(item => {
                    return (
                      <v-checkbox
                        v-model={this.filterCriteria["km_in_liquidation"][item]}
                        label={item}
                        value={item}
                      ></v-checkbox>
                    );
                  })}
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "km_in_liquidation")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "dateEndOfVacation",
          title: `Fecha Fin Vacaciones`,
          field: "dateEndOfVacation",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.dateEndOfVacation &&
                  moment(row.dateEndOfVacation).format("DD/MM")}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["dateEndOfVacation"]["from"]}
                    value={this.filterCriteria["dateEndOfVacation"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["dateEndOfVacation"]["to"]}
                    value={this.filterCriteria["dateEndOfVacation"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "dateEndOfVacation")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "f",
          title: this.$t("brands.start_date"),
          field: "start_date",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.start_date !== null
                  ? moment(row.start_date)
                      .local()
                      .format("DD/MM/YYYY")
                  : ""}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["start_date"]["from"]}
                    value={this.filterCriteria["start_date"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["start_date"]["to"]}
                    value={this.filterCriteria["start_date"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "start_date")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "g",
          title: this.$t("brands.end_date"),
          field: "end_date",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.end_date !== null
                  ? moment(row.end_date)
                      .local()
                      .format("DD/MM/YYYY")
                  : ""}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["end_date"]["from"]}
                    value={this.filterCriteria["end_date"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["end_date"]["to"]}
                    value={this.filterCriteria["end_date"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "end_date")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: this.$t("salesforce.status"),
          field: "status",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  {this.user_status.map(item => {
                    return (
                      <v-checkbox
                        v-model={this.filterCriteria["status"][item]}
                        label={item}
                        value={item}
                      ></v-checkbox>
                    );
                  })}
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        }
      ];
      if (!this.isManager) {
        return [...common_cols, this.col_resetpassword, this.col_edit];
      } else {
        return [...common_cols];
      }
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    onChangeDates(date_item_key) {
      if (date_item_key >= 0) {
        if (
          this.selected.user_date_entries[date_item_key].userStartDate >
          this.selected.user_date_entries[date_item_key].userEndDate
        ) {
          this.selected.user_date_entries[
            date_item_key
          ].userEndDate = this.selected.user_date_entries[
            date_item_key
          ].userStartDate;
        }
        if (
          this.selected.user_date_entries[date_item_key].userStartDate &&
          this.selected.user_date_entries[date_item_key].userEndDate
        ) {
          let isOverlapped = false;
          let currentStartDate = this.selected.user_date_entries[date_item_key]
            .userStartDate;
          let currentEndDate = this.selected.user_date_entries[date_item_key]
            .userEndDate;
          this.selected.user_date_entries.map((entry, index) => {
            if (index !== date_item_key) {
              if (
                (currentStartDate >= entry.userStartDate &&
                  currentStartDate <= entry.userEndDate) ||
                (currentEndDate >= entry.userStartDate &&
                  currentEndDate <= entry.userEndDate) ||
                (currentStartDate >= entry.userStartDate &&
                  currentEndDate <= entry.userEndDate)
              ) {
                isOverlapped = true;
              }
            }
          });
          this.selected.isSelectableDates = !isOverlapped;
        }
      }
    },
    removeLastDateEntry() {
      if (this.selected.user_date_entries.length > 1) {
        this.selected.user_date_entries.pop();
      }
    },
    addNewDateEntry() {
      let isAddAvailable = false;
      if (this.selected && this.selected?.user_date_entries) {
        if (this.selected.user_date_entries.length > 0) {
          let lastItem = this.selected.user_date_entries[
            this.selected.user_date_entries.length - 1
          ];
          if (lastItem?.userStartDate && lastItem?.userEndDate) {
            isAddAvailable = true;
          }
        } else {
          isAddAvailable = true;
        }
      }
      if (isAddAvailable) {
        let push_item = {
          userStartDate: null,
          userEndDate: null,
          orderNum: this.selected.user_date_entries.length + 1
        };
        this.selected.user_date_entries.push(push_item);
      }
    },
    isLastUserDateEntry(user_date_entries, key) {
      console.log("user_date_entries, key - ", user_date_entries, key);
      if (parseInt(key) < user_date_entries.length - 1) return false;
      return true;
    },
    async search_brands(val) {
      this.isProjectsSearching = true;
      let fetch_url = "salesforce/users/get_projects?filter_name=" + val;
      this.prevsearch = val;
      const resp = await ApiService.get(fetch_url);
      this.filtered_projects = resp.data;
      this.isProjectsSearching = false;
    },
    async searchParent(val) {
      this.isParentLoading = true;
      let fetch_url = "salesforce/users/get_parent_list?parent_name=" + val;
      const resp = await ApiService.get(fetch_url);
      this.parent_items = resp.data;
      this.isParentLoading = false;
    },
    update_user_type(val) {
      // if (val !== "spv" && val !== "gpv") {
      //   this.selected.parent_id = null;
      // }
    },

    cancelFilter() {
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        this.filterCriteria[key] = null;
      }
      this.getDataFromApi();
    },

    changeFilter() {
      this.getDataFromApi();
    },

    getQueryParams(options) {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "start_date" || key === "end_date") {
            let convert_value = {
              from: "",
              to: ""
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = moment(value["from"], "YYYY-MM-DD")
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ");
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = moment(value["to"], "YYYY-MM-DD")
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ");
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.loading = true;
      this.showloadingspinner();
      let query_params_string = this.getQueryParams(this.options);
      let fetch_url = "salesforce/users";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.users = resp.data;
      this.totalusers = resp.total;

      let users = [];
      this.users.map(user => {
        let item = { ...user };
        if (user.Parent !== null) {
          item.parent_user_name = user.Parent.username;
        }
        if (user.routes && user.routes.length > 0) {
          item.route_value = user.routes
            .map(elem => {
              return elem.name;
            })
            .join(",");
        }
        users.push(item);
        return user;
      });

      this.user_type = [];
      for (const [key, role] of Object.entries(resp.user_roles)) {
        this.user_type.push(role);
      }
      this.user_status = Object.values(resp.user_status);
      this.km_in_liquidations = Object.values(resp.km_in_liquidations);
      this.landingPages = Object.values(resp.landingPages);
      if (this.users.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }

      this.departments = resp.dep_data;
      this.routes = resp.route_data;
      this.user_list = resp.user_list;
      this.companies = resp.company_data;

      this.liquidationTypes = resp.liquidationTypes;

      this.users = users;
      this.loading = false;
      this.closeloadingspinner();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        cancelFilterKey === "dateEndOfVacation" ||
        cancelFilterKey === "start_date" ||
        cancelFilterKey === "end_date"
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (
        cancelFilterKey === "status" ||
        cancelFilterKey === "km_in_liquidation" ||
        cancelFilterKey === "role" ||
        cancelFilterKey === "department" ||
        cancelFilterKey === "discount_km" ||
        cancelFilterKey === "liquidationType" ||
        cancelFilterKey === "companyCode"
      ) {
        this.filterCriteria[cancelFilterKey] = {};
      } else {
        this.filterCriteria[cancelFilterKey] = null;
      }
      this.getDataFromApi();
    },

    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    createSelectedModel(item) {
      let model_start_date = _.get(item, "start_date");
      let model_end_date = _.get(item, "end_date");

      let selected_routes = null;
      let routes = _.get(item, "routes");
      if (routes && routes.length > 0) {
        selected_routes = routes[0].id;
      }

      let brands = _.get(item, "brands");
      console.log("projects - ", brands);
      let selected_projects = [];
      if (brands && brands.length > 0) {
        this.filtered_projects = brands;
        brands.map(item => {
          selected_projects.push(item.id);
        });
      } else {
        this.search_brands("");
      }
      let user_date_entries = _.get(item, "user_date_entries");
      if (!user_date_entries) user_date_entries = [];
      let model = {
        id: _.get(item, "id"),
        username: _.get(item, "username"),
        name: _.get(item, "name"),
        surname: _.get(item, "surname"),
        email: _.get(item, "email"),
        phone: _.get(item, "phone"),
        phone_company: _.get(item, "phone_company"),
        dni: _.get(item, "dni"),
        isEdit: _.get(item, "isEdit") ?? "Editar",
        landingPage: _.get(item, "landingPage") ?? "Portal fotos",
        residence: _.get(item, "residence"),
        role: _.get(item, "role"),
        parent_id: _.get(item, "parent_id"),
        routes: selected_routes,
        brands: selected_projects,
        status: _.get(item, "status"),
        km_in_liquidation: _.get(item, "km_in_liquidation"),
        liquidationType: _.get(item, "liquidationType"),
        department: _.get(item, "department"),
        project: _.get(item, "project"),
        companyCode: _.get(item, "companyCode"),
        discount_km: _.get(item, "discount_km"),
        dateEndOfVacation: _.get(item, "dateEndOfVacation"),
        user_date_entries,
        isSelectableDates: true,
        start_date:
          model_start_date !== undefined && model_start_date !== null
            ? moment(model_start_date).format("YYYY-MM-DD")
            : model_start_date,
        end_date:
          model_end_date !== undefined && model_end_date !== null
            ? moment(model_end_date).format("YYYY-MM-DD")
            : model_end_date
      };
      if (
        model.user_date_entries.length === 0 &&
        (model?.start_date || model?.end_date)
      ) {
        model.user_date_entries.push({
          userStartDate: model?.start_date,
          userEndDate: model?.end_date,
          orderNum: 1
        });
      }
      if (!model?.dateEndOfVacation) model.dateEndOfVacation = "02-28";
      if (
        model.id &&
        item.staticpendingholidays &&
        item.staticpendingholidays.length > 0
      ) {
        model.additional_static_pendingholiday_year =
          item.staticpendingholidays[0].appliedYear;
        model.additional_static_pendingholiday_value =
          item.staticpendingholidays[0].pendingholidays;
      }
      console.log(model);
      return model;
    },

    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        let start_date_time = new Date(item.start_date);
        let end_date_time = new Date(item.end_date);
        if (start_date_time - end_date_time > 0) {
          logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
          return;
        }
        if (!item?.user_date_entries || item?.user_date_entries.length === 0) {
          logError(
            `${this.$t("Error")} - ${this.$t("brands.start_date")} ${this.$t(
              "brands.end_date"
            )}`
          );
          return;
        }
        this.isSaving = true;
        const body = _.omit(item);
        let startdate = moment(body.start_date, "YYYY-MM-DD");
        let enddate = moment(body.end_date, "YYYY-MM-DD");
        if (item.start_date) {
          body.start_date = startdate.utc().format("YYYY-MM-DD HH:mm:ssZ");
        }
        if (item.end_date) {
          body.end_date = enddate.utc().format("YYYY-MM-DD HH:mm:ssZ");
        }
        let save_res = null;
        if (body.id) {
          save_res = await ApiService.put(`salesforce/users/${body.id}`, body);
        } else {
          save_res = await ApiService.post(`salesforce/users`, body);
        }
        if (save_res.success) {
          logInfo("Usuario actualizada");
          this.showEdit = false;
          this.getDataFromApi();
        } else {
          logError(save_res.message);
        }
        this.isSaving = false;
      }
    },

    close() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      console.log(this.deleteItemId);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`salesforce/users/${this.deleteItemId}`).then(() => {
          logInfo("Usuario eliminada");
          this.getDataFromApi();
        });
      }
    },

    onResetPassword(item_id) {
      console.log(item_id);
      if (window.confirm(this.$t("salesforce.reset_password_confirm"))) {
        ApiService.post(`salesforce/users/resetpassword/${item_id}`).then(
          () => {
            logInfo("operador eliminado");
          }
        );
      }
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    showloadingspinner() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#users_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    closeloadingspinner() {
      this.loadingInstance.close();
    },

    async downloadExcel() {
      this.showloadingspinner();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "salesforce/users/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "users.xlsx");
        this.closeloadingspinner();
      } catch (error) {
        this.closeloadingspinner();
      }
    },

    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      if (window.confirm(`¿Está seguro de que desea cargar este archivo de Excel?`)) {
        this.isSelecting = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        try {
          const response = await ApiService.post(
            `salesforce/users/uploadexcel`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            this.getDataFromApi();
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
            this.getDataFromApi();
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelecting = false;
          this.isLoadingSpinner = false;
        } catch (error) {
          console.log(error);
        }
      }
    },

    async onChangeAdditionalPendingHolidayYear() {
      await this.changeAdditionalPendingHoliday();
    },

    async onChangeDateEndOfVacation() {
      if (this.selected) {
        let dateEndOfVacation = this.selected.dateEndOfVacation;
        if (dateEndOfVacation) {
          let date_value = new Date(dateEndOfVacation);
          if (date_value && date_value != "Invalid Date") {
            date_value = moment(date_value).format("MM-DD");
            this.selected.dateEndOfVacation = date_value;
          } else {
            this.selected.dateEndOfVacation = "";
          }
        }
      }
    },

    async onChangeAdditionalPendingHolidayValue() {
      console.log(this.selected.additional_static_pendingholiday_value);
    },

    async changeAdditionalPendingHoliday() {
      const {
        id,
        additional_static_pendingholiday_year,
        additional_static_pendingholiday_value
      } = this.selected;
      if (id && additional_static_pendingholiday_year > 1970) {
        let fetch_url = `salesforce/users/get_staticpendingholidays?userId=${this.selected.id}&year=${additional_static_pendingholiday_year}`;
        let response = await ApiService.get(fetch_url);
        if (response.data) {
          this.selected.additional_static_pendingholiday_year =
            response.data.appliedYear;
          this.selected.additional_static_pendingholiday_value =
            response.data.pendingholidays;
        } else {
          this.selected.additional_static_pendingholiday_value = null;
        }
      }
    },

    init() {
      this.getDataFromApi();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style>
.v-btn {
  text-transform: capitalize;
}
table tr td .v-btn {
  margin-bottom: 0 !important;
}
.filter_row td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
td.ve-table-body-td.table-body-cell-even {
  background: #ddd !important;
}
</style>
